<template>
  <div class="page">
    <PageTitle :title="title" :routes="routes"></PageTitle>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import PageTitle from '@/components/PageTitle'
export default {
  name: 'CategroyList',
  components: {
    PageTitle
  },
  computed: {
    title() {
      let name = '类目列表'
      if (this.routes.length > 0) name = this.routes[this.routes.length - 1].name
      return name
    },
    ...mapState({
      routes: 'route'
    })
  },
  mounted() {
    // let path = this.$route.path
    // if (path.indexOf('/') === 0) path = path.substring(1)
    // const currentPage = {
    //   link: path,
    //   name: '类目列表'
    // }
    // this.$store.commit('changeRoute', currentPage)
  },
  beforeDestroy() {
    this.$store.commit('setCategroy')
    this.$store.commit('changeRoute', null)
  },
  beforeRouteUpdate (to, from, next) {
    let path = to.path
    const {pid} = to.query
    if (path.indexOf('/') === 0) path = path.substring(1)
    if (pid) path = path + '?pid=' + pid
    const name = to.name
    const chineseName = {
      CategroySecond: '子类目列表',
      CategroyThird: '叶子类目列表',
      CategroyAdd: '添加类目'
    }
    const toPage = {
      link: path,
      name: chineseName[name]
    }
    this.$store.commit('changeRoute', toPage)
    next()
  }
}
</script>
